<template>
  <v-container fluid>
    <!-- add dropzone to upload and excel file -->
    <v-card class="p-0">
      <v-card-title class="card-header">
        <h3 class="headline mb-0">{{ $t('customers.lang_importCustomers') }}</h3>
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="primary">
          <a href="https://cloud.3pos.de/f/9afa87e657b24480922f/?dl=1" class="text-white text-decoration-none">{{ $t('generic.lang_downloadExcelTemplate') }}</a>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-3">
        <v-row class="p-0">
          <v-col
              v-if="this.error"
              cols="12"
              sm="12"
              md="12"
              class="p-4"
          >
            <div class="w-75 mx-auto">
              <v-alert
                  type="error"
                  outlined
              >
                <div class="d-flex align-center">
                  {{ this.error }}
                </div>
              </v-alert>
            </div>
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="12"
              class="p-4"
          >
            <div class="w-75 mx-auto">
              <file-dropzone
                  :file-types="[
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel',
                ]"
                  v-model="file"
                  file-icon="mdi-microsoft-excel"
                  icon-color="success"
                  @startImport="onDropzoneConfirm"
                  :loading="this.importing"
                  :hide-cancel="this.anyFileUploaded"
                  :confirm-text="
                  this.anyFileUploaded
                    ? $t('generic.lang_finishImportation')
                    : $t('generic.lang_import')
                "
              ></file-dropzone>
            </div>
          </v-col>

          <v-col
              v-if="this.anyFileUploaded"
              cols="12"
              sm="12"
              md="12"
              class="p-0"
          >
            <v-form
                lazy-validation
                ref="columnsForm"
            >
              <Datatable
                  ref="excelPreview"
                  :api-endpoint="ENDPOINTS.CUSTOMERS.IMPORT.DRAFT_GET"
                  no-excel-export
                  auto-fill-headers
                  :elevation="0"
                  @headersUpdated="onHeadersUpdated"
                  :showRightAndLeftBorder="true"
                  :showSelectCheckbox="false"
                  :show-header="false"
              >
                <template
                    v-for="(_header, i) in headers"
                    v-slot:[`header.${_header.value}`]="{ header }"
                >
                  <div
                      class="py-2"
                      :key="_header.value + i"
                  >
                    <v-select
                        dense
                        outlined
                        :items="databaseColumns"
                        item-value="key"
                        item-name="text"
                        :label="`${$t('generic.lang_column')} ${i}`"
                        v-model="columnsToSubmit[header.value]"
                        class="database-columns-mapping-list"
                        :rules="[(v) => !!v]"
                        hide-details
                    >
                      <template v-slot:item="{ item, on }">
                        <span
                            class="columns-list-element"
                            :class="[isSelected(item, i) ? 'to-be-hidden' : '']"
                            v-on="on"
                        >{{ item.text }}</span
                        >
                      </template>
                    </v-select>
                  </div>
                </template>
              </Datatable>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FileDropzone from '../common/FileDropzone.vue';
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import Datatable from '@/components/datatable/Datatable';

export default {
  name: "CustomerImportComponent",
  components: {
    FileDropzone,
    Datatable,
  },
  data() {
    return {
      ENDPOINTS,
      anyFileUploaded: false,
      importing: false,
      file: null,
      error: null,
      headers: [],
      columnsToSubmit: {},
      databaseColumns: [
        {
          key: 'none',
          text: this.$t('generic.lang_none'),
        },
        {
          key: 'salutation',
          text:this.$t('customers.lang_customerSalutationList'),
        },
        {
          key: 'first_name',
          text:this.$t('generic.lang_name'),
        },
        {
          key: 'last_name',
          text:this.$t('customers.lang_lastName'),
        },
        {
          key: 'customerGroup',
          text:this.$t('customers.lang_nameCustomerGroup'),
        },
        {
          key: 'customerCardID',
          text:this.$t('customers.lang_custCard'),
        },
        {
          key: 'company',
          text:this.$t('generic.lang_company'),
        },
        {
          key: 'VATID',
          text:this.$t('accounting.lang_VATID'),
        },
        {
          key: 'custDebitorenNr',
          text:this.$t('settings.lang_defaultDebitorNo'),
        },
        {
          key: 'position',
          text:this.$t('generic.lang_position'),
        },
        {
          key: 'street',
          text:this.$t('generic.lang_street'),
        },
        {
          key: 'number',
          text:this.$t('customers.lang_cust_streetNo'),
        },
        {
          key: 'zip',
          text:this.$t('customers.lang_cust_zip'),
        },
        {
          key: 'city',
          text:this.$t('settings.lang_city'),
        },
        {
          key: 'country',
          text:this.$t('generic.lang_country'),
        },
        {
          key: 'birthday',
          text:this.$t('customers.lang_cust_birth'),
        },
        {
          key: 'tel_private',
          text:this.$t('customers.lang_cust_tel_priv'),
        },
        {
          key: 'mobil_private',
          text:this.$t('customers.lang_cust_mobil_priv'),
        },
        {
          key: 'fax',
          text:this.$t('generic.lang_fax'),
        },
        {
          key: 'email',
          text:this.$t('generic.lang_email'),
        },
        {
          key: 'website',
          text:this.$t('generic.lang_Website'),
        },
        {
          key: 'notes',
          text:this.$t('generic.lang_notes'),
        },
      ],
    };
  },

  methods: {
    onHeadersUpdated(_headers) {
      this.headers = [...(_headers ?? [])];

      // fill columnsToSubmit with default values (none)
      if (Object.keys(this.columnsToSubmit).length > 0) return;
      this.headers.forEach((header, index) => {
        console.log(this.databaseColumns[index + 1]);

        let suitableValue = 'none';
        // we use index + 1 to ignore the first column (none)
        if (this.databaseColumns.length >= index + 1)
          suitableValue = this.databaseColumns[index + 1]?.key ?? 'none';
        this.columnsToSubmit[header.value] = suitableValue;
      });
    },
    loadUploadedFileContent() {
      this.loading = true;

      this.axios
          .post(ENDPOINTS.CUSTOMERS.IMPORT.DRAFT_GET)
          .then((res) => {
            //console.log(res.data);
          })
          .catch(() => {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    reset() {
      this.file = null;
      this.anyFileUploaded = false;
      this.columnsToSubmit = {};
      this.error = null;
    },
    onDropzoneConfirm() {
      if (!this.anyFileUploaded) this.onStartImport();
      else {
        this.finishImport();
      }
    },
    removeNoneProperties(obj) {
      for (let key in obj) {
        if (obj[key] === 'none') {
          delete obj[key];
        }
      }
      return obj;
    },

    finishImport() {
      if (!this.$refs?.columnsForm?.validate()) return;
      this.importing = true;

      this.axios
          .post(
              ENDPOINTS.CUSTOMERS.IMPORT.FINISH,
              this.removeNoneProperties({ ...this.columnsToSubmit })
          )
          .then((res) => {
            if (res.data.success) {
              this.anyFileUploaded = true;
              Events.$emit('showSnackbar', {
                message: this.$t('erp.lang_itemsSuccessfullImported'),
                color: 'success',
              });
              this.reset();
            } else {
              Events.$emit('showSnackbar', {
                message: res.data.msg,
                color: 'error',
              });
            }
          })
          .catch(() => {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          })
          .finally(() => {
            this.importing = false;
          });
    },
    onStartImport() {
      this.importing = true;
      const formData = new FormData();
      formData.append('file', this.file);

      this.axios
          .post(ENDPOINTS.CUSTOMERS.IMPORT.DRAFT_CREATE, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.anyFileUploaded = true;
              Events.$emit('showSnackbar', {
                message: this.$t('erp.lang_itemsSuccessfullImported'),
                color: 'success',
              });
              this.loadUploadedFileContent();
            } else {
              Events.$emit('showSnackbar', {
                message: res.data.msg,
                color: 'error',
              });
            }
          })
          .catch(() => {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          })
          .finally(() => {
            this.importing = false;
          });
    },
  },
  mounted() {},
  computed: {
    isSelected() {
      return (column, position) => {
        if (column.key === 'none') return;

        const selectedValues = Object.values(this.columnsToSubmit);
        // ignore if is selected in the current position
        if (selectedValues[position] === column.key) return false;
        return selectedValues?.includes(column.key);
      };
    },
    excelPreview() {
      return this.$refs.excelPreview;
    },
  },
  watch: {
    '$refs.excelPreview': {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style>
div.v-list-item:has(span.columns-list-element.to-be-hidden) {
  display: none;
}
</style>

